import { Component, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', '../styles.scss'],
})
export class AppComponent {
  title = 'PadelT Professionals';

  // Inicialización de ngx-translate
  constructor(
    private translate: TranslateService,
    private router: Router,
    private authService: AuthService,
    @Inject(DOCUMENT) private document: Document
  ) {
    translate.setDefaultLang('ca-ES');
    translate.use('ca-ES');
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {window.scrollTo({ top: 0, behavior: 'smooth' })}, 200);
      }
    });
  }

  useLanguage(language: string): void {
    this.document.documentElement.lang = language;
    this.translate.use(language);
  }
}
