import { Component, inject, Injectable, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { SidebarToggleService } from '../../services/sidebarToggle.service';
import { lastValueFrom, Subscription } from 'rxjs';
import { UsuariService } from '../../services/api/usuari.service';
import { Usuari } from '../../types/Usuari';
import { Empresa } from '../../types/Empresa';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';
import { SidepanelComponent } from '../sidepanel/sidepanel.component';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ContextService } from '../../services/api/context.service';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
@Injectable({ providedIn: 'root' })
export class HeaderComponent {
  @ViewChild(SidepanelComponent) sidepanel!: SidepanelComponent;

  authService = inject(AuthService);
  usuariService = inject(UsuariService);
  contextService = inject(ContextService);
  breakpointObserver = inject(BreakpointObserver);

  loginSubscription!: Subscription;
  newProfielPicSubscription!: Subscription;
  sidebarSubscription!: Subscription;

  idioma: string = 'CA';
  idiomes: { name: string; code: string }[] = [
    { name: 'Català', code: 'CA' },
    { name: 'Espanyol', code: 'ES' },
  ];
  siedpanelOpen: boolean = false;
  currentRoute: string = '';
  codiPoblacio: string = '';
  iconMenu: string = 'menu';
  empreses: Empresa[] = [];
  empresa!: Empresa;
  empresaNova!: Empresa;
  desplegadoEmpresa: boolean = true;
  desplegadoIdioma: boolean = true;
  logedIn: boolean = false;
  isFocused: boolean = false;
  hamburguesa: boolean = true;
  clicked: boolean =  false;
  isWeb: boolean = false;
  poblacions: any[] = [];
  seccions: boolean[] = [false, false, false];
  formData!: FormGroup;

  userData: Usuari = {
    nom: '',
    cognom: '',
    secCognom: '',
    estat: false,
    dniUsuari: '',
    perfilId: 0,
    codiPerfil: '',
    adrecaElectronica: '',
    telefon: '',
    empresaUsuariId: 0,
    usuariId: 0,
    nifEmpresa: '',
    registrat: false
  };
  image: { usuariFitxer64: string; nomFitxer: string } = {
    usuariFitxer64: '',
    nomFitxer: '',
  };

  constructor(
    private translate: TranslateService,
    private router: Router,
    private sidebarToggleService: SidebarToggleService,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {
    this.breakpointObserver
      .observe(['(min-width: 916px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isWeb = true;
        }
      });
    this.translate.setDefaultLang(`${this.idioma.toLowerCase()}-ES`);
    this.translate.use(`${this.idioma.toLowerCase()}-ES`);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.urlAfterRedirects;
        this.hamburguesa = !this.currentRoute.includes('registre');
      }
    });
    this.formData = this.fb.group({
      raoSocial: ['', Validators.required],
      nifEmpresa: [{ value: '', disabled: true }, Validators.required],
      rol: ['', Validators.required],
      adrecaElectronicaEmpresa: ['', [Validators.required, Validators.email]],
      telefonEmpresa: ['', [Validators.required]],
      adrecaEmpresa: ['', Validators.required],
      codiPostalEmpresa: ['', [Validators.required]],
      poblacio: ['', Validators.required],
      adrecaElectronicaProfessional: ['', Validators.required],
      telefonProfessional: [''],
      dniUsuari: [{ value: this.userData.dniUsuari, disabled: true }],
      nom: [{ value: `${this.userData.nom || ""} ${this.userData.cognom || ""} ${this.userData.secCognom || ""}`, disabled: true }],
    });
  }

  async ngOnInit() {
    await this.loadUserData();
    this.loginSubscription = this.authService.userLogin$.subscribe(async () => {
      await this.loadUserData();
    });
    this.sidebarSubscription =
      this.sidebarToggleService.setIconSubject$.subscribe(() => {
        this.iconMenu = 'menu';
      });
    this.newProfielPicSubscription =
      this.sidebarToggleService.newProfilePicSubject$.subscribe(async () => {
        await this.setProfilePic();
      });
  }

  async loadUserData() {
    const usuari = localStorage.getItem('usuari');
    const usuariEmpreses = localStorage.getItem('empresesUsuari');
    const empresa = localStorage.getItem('empresa');
    if (usuari) {
      this.userData = JSON.parse(usuari);
      if (this.userData.nom && this.userData.cognom) {
        this.logedIn = true;
      }
      if (usuariEmpreses) {
        this.empreses = JSON.parse(usuariEmpreses);
      }
      if (empresa) {
        this.empresa = JSON.parse(empresa);
      }
      await this.setProfilePic();
    }
  }

  empresaRao(rao: string) {
    if(rao.length < 22) {
      return rao;
    }
    return rao.substring(0,20).concat("...");
  }

  async setProfilePic() {
    try {
      const image = await lastValueFrom(
        this.usuariService.getImage(
          this.userData.nomFitxer || '',
          this.userData.empresaUsuariId.toString()
        )
      );
      const ext = image.dadesResultat.nomFitxer.split('.');
      this.image.nomFitxer = image.dadesResultat.nomFitxer;
      this.image.usuariFitxer64 = `data:image/${ext[
        ext.length - 1
      ].toLowerCase()};base64,${image.dadesResultat.usuariFitxer64}`;
    } catch (err) {
      console.log('Imatge de perfil no trobada');
    }
  }

  async logout() {
    await lastValueFrom(this.authService.logout(this.userData.dniUsuari));
    localStorage.removeItem('usuari');
    localStorage.removeItem('empresesUsuari');
    localStorage.removeItem('empresa');
    localStorage.removeItem('jwt');
    localStorage.removeItem('uuid');
    localStorage.removeItem('refresh');
    window.location.href = environment.logout;
  }

  desplegarIdioma() {
    this.desplegadoIdioma = !this.desplegadoIdioma;
  }

  desplegarEmpresa() {
    this.desplegadoEmpresa = !this.desplegadoEmpresa;
  }

  canviarIdioma(code: string) {
    this.idioma = code;
    this.translate.use(`${code.toLowerCase()}-ES`);
  }

  errorValidator(error: any) {
    this.sidepanel.close();
    this.siedpanelOpen = false;
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      disableClose: true,
      data: {
        title: 'ERRORS.'.concat(error.codiError),
        message: `Camp: ${this.camelToNormal(error.camp)}`,
        icon: 'warning',
        showCancel: false,
      },
    });
    dialogRef.componentInstance.closeEvent.subscribe(
      async (result: { accept: boolean }) => {
        this.dialog.closeAll();
        this.clicked = false;
        this.sidepanel.open();
        this.siedpanelOpen = true;
      }
    );
  }

  camelToNormal = (text: string) => {
    return text
      .replace(/([A-Z])/g, ' $1')
      .toLowerCase()
      .trim()
      .replace(/\b\w/g, (char) => char.toUpperCase())
      .replace('Adreca', 'Adreça')
      .replace('Email', 'Adreça electrònica');
  };

  fieldValidator() {
    const requiredFields = [
      'raoSocial',
      'nifEmpresa',
      'rol',
      'adrecaElectronicaEmpresa',
      'adrecaElectronicaProfessional',
      'telefonEmpresa',
      'adrecaEmpresa',
      'codiPostalEmpresa',
      'poblacio'
    ];
    const invalidMail = (field: string, control: AbstractControl<any, any>) => {
      if(field.includes("adrecaElectronica") && !/^[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,5}$/.test(control.value)) {
        return true;
      }
      return false;
    }
    for (const field of requiredFields) {
      const control = this.formData.get(field);
      if (!control || control.invalid || control.value === '' || invalidMail(field, control)) {
        this.sidepanel.close();
        this.siedpanelOpen = false;
        const dialogRef = this.dialog.open(ErrorModalComponent, {
          disableClose: true,
          data: {
            message: `El camp ${this.camelToNormal(field)} es incorrecte`,
            icon: 'warning',
            showCancel: false,
            title: 'Error de validació',
          },
        });
        dialogRef.componentInstance.closeEvent.subscribe(() => {
          this.dialog.closeAll();
          this.sidepanel.open();
          this.siedpanelOpen = true;
          this.clicked = false;
        });
        return false;
      }
    }
    return true;
  }

  async newEmpresa() {
    if (!this.fieldValidator()) {
      return;
    }
    if(!this.codiPoblacio && this.formData.value.poblacio){
      const poblacions = await lastValueFrom(this.contextService.getPoblacions(this.formData.value.poblacio));
      const poblacio = poblacions.find((el) => el.descripcio === this.formData.value.poblacio);
      if(poblacio) {
        this.codiPoblacio = poblacio.codiElement;
      } else{
        this.errorValidator({codiError: "POBLACIO", camp: "Població"})
        return;
      }
    }
    this.clicked = true;
    const empresaBody = {
      estat: true,
      nif: this.empresaNova.nifEmpresa,
      raoSocial: this.formData.value.raoSocial,
      adreca: this.formData.value.adrecaEmpresa,
      codiPostal: this.formData.value.codiPostalEmpresa,
      adrecaElectronica: this.formData.value.adrecaElectronicaEmpresa,
      telefon: this.formData.value.telefonEmpresa,
      rol: this.formData.value.rol,
      empresaId: this.empresaNova.empresaId,
      codiPoblacio: this.codiPoblacio,
    };
    const usuariBody = {
      estat: true,
      dniUsuari: this.userData.dniUsuari,
      nifEmpresa: this.empresaNova.nifEmpresa || '',
      nom: this.userData.nom,
      cognom: this.userData.cognom,
      secCognom: this.userData.secCognom,
      adrecaElectronica: this.formData.value.adrecaElectronicaProfessional,
      telefon: this.formData.value.telefonProfessional,
      codiPerfil: this.userData.codiPerfil,
      empresaUsuariId: this.empresaNova.empresaUsuariId,
      usuariId: this.userData.usuariId,
      perfilId: this.userData.perfilId,
      registrat: true
    };

    const refresh = localStorage.getItem('refresh') || '';
    const empresaOld = JSON.parse(localStorage.getItem('empresa') || '');
    const newTokens = await lastValueFrom(this.authService.refreshToken(refresh))
    const newToken = await lastValueFrom(
      this.authService.refreshTokenEmpresa(
        newTokens.refresh_token,
        this.empresaNova.empresaUsuariId
      )
    );
    if (newToken.accessToken) {   
      newToken.usuari.registrat = true;
      localStorage.setItem('jwt', newToken.accessToken);
      localStorage.setItem('refresh', newToken.refreshToken);
      localStorage.setItem('empresa', JSON.stringify(newToken.empresa));
      localStorage.setItem('usuari', JSON.stringify(newToken.usuari));
    }
    const emp = await lastValueFrom(
      this.usuariService.registraEmpresa(
        empresaBody,
        this.empresaNova.empresaId
      )
    );
    const user = await lastValueFrom(
      this.usuariService.registraUsuari(
        usuariBody,
        this.empresaNova.empresaUsuariId.toString()
      )
    );
    if (emp.validacioResultat || user.validacioResultat) {
      if (emp.validacioResultat) {
        this.errorValidator(emp.validacioResultat[0]);
      } else if (user.validacioResultat) {
        this.errorValidator(user.validacioResultat[0]);
      }
      const newToken = await lastValueFrom(
        this.authService.refreshTokenEmpresa(
          refresh,
          empresaOld.empresaUsuariId
        )
      );
      if (newToken.accessToken) {
        localStorage.setItem('jwt', newToken.accessToken);
        localStorage.setItem('refresh', newToken.refreshToken);
        localStorage.setItem('usuari', JSON.stringify(newToken.usuari));
        localStorage.setItem('empresa', JSON.stringify(newToken.empresa));
      }
      return;
    }
    this.sidepanel.close();
    this.siedpanelOpen = false;
    const jsonEmpreses = localStorage.getItem('empresesUsuari') || '';
    let empreses = JSON.parse(jsonEmpreses);
    empreses = empreses.filter(
      (el: { empresaUsuariId: number }) =>
        el.empresaUsuariId != this.empresaNova.empresaUsuariId
    ); 
    empreses.push({
      "empresaUsuariId": this.empresaNova.empresaUsuariId,
      "empresaId": emp.dadesResultat.objectMantId,
      "nifEmpresa": emp.dadesResultat.nif,
      "raoSocial": emp.dadesResultat.raoSocial,
      "seleccionat":true,
      "registrat":true,
      "codiPerfil": "PROF_ADMIN",
      "rol": emp.dadesResultat.rol,
      "estat":true
    });
    localStorage.setItem('usuari', JSON.stringify(user.dadesResultat));
    localStorage.setItem('empresa', JSON.stringify({...emp.dadesResultat, empresaId: emp.dadesResultat.objectMantId}));
    localStorage.setItem('empresesUsuari', JSON.stringify(empreses))
    window.location.href = "./#/inici";
    window.location.reload();
  }

  async canviarEmpresa(empresaCanvi: Empresa) {
    const dadesEmpresa = await lastValueFrom(this.contextService.getEmpreses(empresaCanvi.nifEmpresa || ""))
    const empresa = dadesEmpresa[0]

    if (!empresa.raoSocial || !empresa.rol || !empresa.adrecaElectronica|| !empresa.telefon || !empresa.adreca || !empresa.cp || !empresa.poblacio) {
      if(empresaCanvi.codiPerfil !== "PROF_ADMIN"){
        const dialogRef = this.dialog.open(ErrorModalComponent, {
          disableClose: true,
          data: {
            title: 'REGISTRE.NO_ADMIN',
            message: 'REGISTRE.NO_ADMIN_SUB',
            icon: 'warning',
            showCancel: false,
          },
        });
        dialogRef.componentInstance.closeEvent.subscribe(
          async (result: { accept: boolean }) => {
            this.dialog.closeAll();
          }
        );
        return;
      }
      this.empresaNova = empresaCanvi;
      this.formData = this.fb.group({
        raoSocial: [empresa.raoSocial || "", Validators.required],
        nifEmpresa: [
          { value: empresa.nif, disabled: true },
          Validators.required,
        ],
        rol: [empresa.rol || "", Validators.required],
        adrecaElectronicaEmpresa: [empresa.adrecaElectronica || "", Validators.pattern(/^[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,5}$/)],
        telefonEmpresa: [empresa.telefon || "", Validators.required],
        adrecaEmpresa: [empresa.adreca || "", Validators.required],
        codiPostalEmpresa: [empresa.cp || "", Validators.pattern(/^\d{5}$/)],
        poblacio: [empresa.poblacio || "", Validators.required],
        adrecaElectronicaProfessional: [empresaCanvi.adrecaElectronica || "", Validators.pattern(/^[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,5}$/)],
        telefonProfessional: [empresaCanvi.telefon || ""],
        dniUsuari: [{ value: this.userData.dniUsuari, disabled: true }],
        nom: [{ value: `${this.userData.nom || ""} ${this.userData.cognom || ""} ${this.userData.secCognom || ""}`, disabled: true }],
      });
      this.sidepanel.open();
      this.siedpanelOpen = true;
      return;
    }

    this.empresa = empresaCanvi;
    const refresh = localStorage.getItem('refresh');
    if(!refresh) {
      window.location.href = "./#/"
      return;
    }
    const dataNewRefresh = await lastValueFrom(this.authService.refreshToken(refresh));
    if (dataNewRefresh.refresh_token) {
      const newToken = await lastValueFrom(
        this.authService.refreshTokenEmpresa(refresh, this.empresa.empresaUsuariId)
      );
      if (newToken.accessToken) {
        localStorage.setItem('jwt', newToken.accessToken);
        localStorage.setItem('refresh', newToken.refreshToken);
        localStorage.setItem('usuari', JSON.stringify(newToken.usuari));
        localStorage.setItem('empresa', JSON.stringify(newToken.empresa));
        window.location.href = './#/inici';
        window.location.reload();
      }
    } else {
      this.logout();
    }
  }

  openSidebar() {
    this.iconMenu = this.iconMenu == 'menu' ? 'close' : 'menu';
    this.sidebarToggleService.emitToggleSidebar();
  }

  goprofile() {
    this.router.navigate([`/perfil`]);
  }

  getRolValue(rol: string) {
    if (!rol) {
      return '';
    }
    if (rol.toUpperCase() === 'CARRTRANSP') {
      return 'Carregador, Transportista';
    } else {
      return rol.toLowerCase();
    }
  }

  isChecked(rol: string = '') {
    const currentRol = this.formData.value.rol;
    return currentRol.includes(rol);
  }

  onRoleChange(role: string) {
    let currentRol = this.formData.value.rol || '';
    if (this.isChecked(role)) {
      if (currentRol === 'CARRTRANSP') {
        currentRol = role === 'CARR' ? 'TRANSPORTISTA' : 'CARREGADOR';
      } else {
        currentRol = '';
      }
    } else {
      if (currentRol.length) {
        currentRol = 'CARRTRANSP';
      } else {
        currentRol = role === 'CARR' ? 'CARREGADOR' : 'TRANSPORTISTA';
      }
    }
    this.formData.patchValue({ rol: currentRol });
  }

  async changeContextPoblacio($event: any) {
    this.codiPoblacio = "";
    if ($event.target.value.length >= 3) {
      this.poblacions = await lastValueFrom(
        this.contextService.getPoblacions($event.target.value)
      );
    }
  }

  onFocus() {
    this.isFocused = true;
  }

  onBlur() {
    setTimeout(() => {
      this.isFocused = false;
      this.poblacions = [];
    }, 200);
  }

  setSeccio(seccio: number) {
    this.seccions[seccio] = !this.seccions[seccio];
  }

  setFormValue(value: string, codi: string = '') {
    this.formData.patchValue({ poblacio: value });
    this.codiPoblacio = codi;
  }
}
