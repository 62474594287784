import { Component, Inject, EventEmitter, Output, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Stmc } from '../../types/Stmc';
import { Router } from '@angular/router';
import { ContextService } from '../../services/api/context.service';
import { lastValueFrom } from 'rxjs';
import { StmcService } from '../../services/api/stmc.service';

@Component({
  selector: 'app-view-stmc',
  styleUrl: './view-stmc.component.scss',
  template: `
    <div class="ctti-modal">
      <div class="cross" (click)="closeModal()">
        <mat-icon> close </mat-icon>
      </div>
      <h5 class="titleModal">{{ 'CARREGADOR.DETALL' | translate }}</h5>

      <div class="headerModal">
        <div class="codiCopiar">
          <label
            >{{ 'CARREGADOR.LABEL_CODI' | translate }}:
            <span class="negreta">{{ data.stmc.codiStmc }}</span></label
          >
          <p class="red dflex" (click)="copyCode(data.stmc.codiStmc)">
            @if(copied) {
            <mat-icon>check</mat-icon
            ><span class="copiat">{{ 'COPIAT' | translate }}</span>
            }@else {
            <mat-icon>content_copy</mat-icon>
            }
          </p>
        </div>
        <div [class]="data.stmc.estatStmc.toLowerCase()">
          {{ textTipus(data.stmc.estatStmc) | translate }}
          <div [class]="data.stmc.estatDc.toLowerCase()">
            <mat-icon>{{ iconaTipus(data.stmc.estatDc) }}</mat-icon>
          </div>
        </div>
      </div>

      <div class="dades-smtc">
        <div class="dada-smtc">
          <div class="icon"><mat-icon>calendar_today</mat-icon></div>
          <div>
            <label>{{ 'CARREGADOR.LABEL_DATA' | translate }}:</label>
            <p>{{ data.stmc.dataRealitzacio }}</p>
          </div>
        </div>
        <div class="dada-smtc">
          <div class="icon"><mat-icon>location_on</mat-icon></div>
          <div>
            <label>{{ 'CARREGADOR.LABEL_RUTA' | translate }}:</label>
            <p>
              {{ data.stmc.poblacioOrigen + ' - ' + data.stmc.poblacioDesti }}
            </p>
          </div>
        </div>
        <div class="dada-smtc">
          <div class="icon"><mat-icon>local_shipping</mat-icon></div>
          <div>
            <label>{{ 'CARREGADOR.LABEL_TRANSPORTISTA' | translate }}:</label>
            <p>{{ data.stmc.raoSocialTransportista }}</p>
          </div>
        </div>
        <div class="dada-smtc">
          <div class="icon"><mat-icon>inventory_2</mat-icon></div>
          <div>
            <label>{{ 'CARREGADOR.LABEL_MERCADERIA' | translate }}:</label>
            <p>{{ getMercaderia(data.stmc.tipusMercaderia) | translate }}</p>
          </div>
        </div>
        <div class="dada-smtc">
          <div class="icon"><mat-icon>directions_car</mat-icon></div>
          <div>
            <label>{{ 'CARREGADOR.LABEL_MATRICULA' | translate }}:</label>
            <p>{{ data.stmc.matriculaVehicle }}</p>
          </div>
        </div>
        <div class="dada-smtc">
          <div class="icon">
            <!-- <mat-icon>forklift</mat-icon> -->
            <img
              width="16"
              heigth="16"
              src="../../../assets/images/forklift_mini.png"
              alt="carregador"
            />
          </div>
          <div>
            <label>{{ 'CARREGADOR.LABEL_CARREGADOR' | translate }}:</label>
            <p>{{ data.stmc.raoSocialCarregador }}</p>
          </div>
        </div>
      </div>

      @if(sftp) {
      <div class="dades-smtc">
        <div class="dada-smtc">
          <div>
            <label>{{ 'CARREGA.CODI_STMC_CC' | translate }}:</label>
            <p>{{ data.stmc.codiStmcCarregador || '' }}</p>
          </div>
        </div>
        <div class="dada-smtc">
          <div>
            <label>{{ 'CARREGA.CODI_STMC_TE' | translate }}:</label>
            <p>{{ data.stmc.codiStmcTransportista || '' }}</p>
          </div>
        </div>
        <div class="dada-smtc">
          <div>
            <label>{{ 'CARREGA.NOM_FITXER' | translate }}:</label>
            <p>{{ sftp[0].nomFitxerCarrega || '' }}</p>
          </div>
        </div>
        <div class="dada-smtc">
          <div>
            <label>{{ 'CARREGA.DATA_HORA' | translate }}:</label>
            <p>{{ sftp[0].dataProcessat || '' }}</p>
          </div>
        </div>
      </div>
      }
      <div class="botones">
        <div class="ctti-modal__actions gap-3">
          <button
            class="ctti-button ctti-button-inv"
            mat-dialog-close
            (click)="closeModal()"
          >
            {{ 'MODAL.TORNAR' | translate }}
          </button>
        </div>
        <div class="ctti-modal__actions gap-3">
          <button
            class="ctti-button ctti-button-filled"
            mat-dialog-close
            (click)="editStmc()"
            *ngIf="
              ['ESBORRANY', 'PLANIFICAT', 'DC_CREAT', 'REBUTJAT'].includes(
                data.stmc.estatStmc
              )
            "
          >
            <mat-icon>edit</mat-icon>{{ 'MODAL.EDITAR' | translate }}
          </button>
        </div>
      </div>
    </div>
  `,
})
export class ViewStmcComponent {
  @Output() closeEvent = new EventEmitter<void>();
  contextService = inject(ContextService);
  stmcService = inject(StmcService);
  mercaderies: any[] = [];
  copied: boolean = false;
  sftp!: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { stmc: Stmc; pag: string },
    private router: Router
  ) {}

  async ngOnInit() {
    this.mercaderies = await lastValueFrom(
      this.contextService.getTotsMercaderies()
    );
    const stmcDetall = await lastValueFrom(
      this.stmcService.getStmcById(this.data.stmc.objectMantId)
    );
    this.sftp = stmcDetall.dadesResultat.carregasMassivas;
  }

  copyCode(codi: string) {
    navigator.clipboard.writeText(codi);
    this.copied = true;
  }

  getMercaderia(value: string) {
    const item = this.mercaderies.find((el) => el.codiElement === value);
    return item ? item.descripcio : '';
  }

  closeModal() {
    this.closeEvent.emit();
  }
  editStmc() {
    this.closeEvent.emit();
    window.location.href = `./#/${this.data.pag}/stmc/${this.data.stmc.objectMantId}`;
  }

  iconaTipus(tipus: string) {
    switch (tipus) {
      case 'DC_MODIFICACIO':
        return 'sd_card_alert';
      case 'DC_CREAT':
        return 'task';
      case 'DC_NO_CREAT':
        return 'error_outline';
      default:
        return 'error_outline';
    }
  }

  textTipus(value: string) {
    if (!value) {
      return '';
    }
    return `CARREGADOR.${value}`;
  }
}
