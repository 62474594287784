import { Component, Inject, EventEmitter, Output, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContextService } from '../../services/api/context.service';
import { lastValueFrom } from 'rxjs';
import { StmcService } from '../../services/api/stmc.service';
import { StmcPopulated } from '../../types/StmcPopulated';

@Component({
  selector: 'app-mes-info',
  styleUrl: './mes-info.component.scss',
  template: `
    <div class="ctti-modal">
      <div class="cross" (click)="closeModal()">
        <mat-icon> close </mat-icon>
      </div>
      <h5 class="titleModal">{{ 'STMC.MES_INFO' | translate }}</h5>
        
      <div class="dades-smtc">
        <div class="dada-smtc">
          <div>
            <label>{{ 'CARREGA.CODI_STMC_CC' | translate }}:</label>
            <p>{{ data.stmc.codiStmcCarregador || '' }}</p>
          </div>
        </div>
        <div class="dada-smtc">
          <div>
            <label>{{ 'CARREGA.CODI_STMC_TE' | translate }}:</label>
            <p>{{ data.stmc.codiStmcTransportista || '' }}</p>
          </div>
        </div>
        <div class="dada-smtc">
          <div>
            <label>{{ 'CARREGA.NOM_FITXER' | translate }}:</label>
            <p>{{ data.stmc.carregasMassivas[0].nomFitxerCarrega || '' }}</p>
          </div>
        </div>
        <div class="dada-smtc">
          <div>
            <label>{{ 'CARREGA.DATA_HORA' | translate }}:</label>
            <p>{{ data.stmc.carregasMassivas[0].dataProcessat || '' }}</p>
          </div>
        </div>
      </div>
      
      <div class="botones">
        <div class="ctti-modal__actions gap-3">
          <button
            class="ctti-button ctti-button-inv"
            mat-dialog-close
            (click)="closeModal()"
          >
            {{ 'MODAL.TORNAR' | translate }}
          </button>
        </div>
      </div>
    </div>
  `,
})
export class MesInfoComponent {
  @Output() closeEvent = new EventEmitter<void>();
  contextService = inject(ContextService);
  stmcService = inject(StmcService);
  copied: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { stmc: StmcPopulated },
  ) {}

  async ngOnInit() {

  }

  copyCode(codi: string) {
    navigator.clipboard.writeText(codi);
    this.copied = true;
  }

  closeModal() {
    this.closeEvent.emit();
  }

  iconaTipus(tipus: string) {
    switch (tipus) {
      case 'DC_MODIFICACIO':
        return 'sd_card_alert';
      case 'DC_CREAT':
        return 'task';
      case 'DC_NO_CREAT':
        return 'error_outline';
      default:
        return 'error_outline';
    }
  }

  textTipus(value: string) {
    if (!value) {
      return '';
    }
    return `CARREGADOR.${value}`;
  }
}
