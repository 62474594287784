import { Component, Inject, EventEmitter, Output, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Stmc } from '../../types/Stmc';
import { Router } from '@angular/router';
import { ContextService } from '../../services/api/context.service';
import { lastValueFrom } from 'rxjs';
import { CarregaService } from '../../services/api/carrega.service';
import { ErrorModalComponent } from '../error-modal/error-modal.component';

@Component({
  selector: 'app-detall-carrega',
  styleUrl: './detall-carrega.component.scss',
  template: `
    <div class="ctti-modal">
      <div class="cross" (click)="closeModal()">
        <mat-icon> close </mat-icon>
      </div>
      <h5 class="titleModal">{{ 'CARREGA.VIEW-TITLE' | translate }}</h5>

      <div class="dades-smtc">
        <div class="dada-smtc">
          <div class="icon"><mat-icon>person</mat-icon></div>
          <div>
            <label>{{ 'CARREGA.USUARI_CARREGAT' | translate }}:</label>
            <p>{{ data.nomUsuariProcessat }}</p>
          </div>
        </div>

        <div class="doble">
          <div class="dada-smtc">
            <div class="icon"><mat-icon>upload_file</mat-icon></div>
            <div>
              <label>{{ 'CARREGA.NOM_FITXER' | translate }}:</label>
              <p>
                {{ data.nomFitxer }}
              </p>
            </div>
          </div>
          <div class="dada-smtc">
            <div class="icon"><mat-icon>dvr</mat-icon></div>
            <div>
              <label>{{ 'CARREGA.ORIGEN_FITXER' | translate }}:</label>
              <p>{{ data.origen }}</p>
            </div>
          </div>
        </div>
        <div class="dada-smtc">
          <div class="icon"><mat-icon>calendar_today</mat-icon></div>
          <div>
            <label>{{ 'CARREGA.DATA_HORA' | translate }}:</label>
            <p>
              {{
                data.dataCarrega ? data.dataCarrega.replaceAll('-', '/') : ''
              }}
            </p>
          </div>
        </div>

        <p class="resultat">{{ 'CARREGA.RESULTAT_PROCES' | translate }}</p>

        <div class="dada-smtc">
          <div class="icon"><mat-icon>calendar_today</mat-icon></div>
          <div>
            <label>{{ 'CARREGA.DATA_HORA_PROC' | translate }}:</label>
            <p> {{
                data.dataProcessatInici ? data.dataProcessatInici.replaceAll('-', '/') : ''
              }}</p>
          </div>
        </div>
        <div class="dada-smtc">
          <div class="icon"><mat-icon>upload_file</mat-icon></div>
          <div>
            <label>{{ 'CARREGA.RESULTAT_GENERAL' | translate }}:</label>
            <div class="resultatsFitxer">
              @if(data.liniesValids > 0) {
              <div class="valid">
                <mat-icon>verified</mat-icon>
                {{ 'CARREGA.VALIDS' | translate }}:
                {{ data.liniesValids }}
              </div>
              } @if(data.liniesAmbErrors > 0) {
              <div class="errors">
                <mat-icon>warning_amber</mat-icon>
                {{ 'CARREGA.ERRORS' | translate }}:
                {{ data.liniesAmbErrors }}
              </div>
              } @if(data.liniesAmbAlertes > 0) {
              <div class="avisos">
                <mat-icon>report</mat-icon>
                {{ 'CARREGA.AVISOS' | translate }}:
                {{ data.liniesAmbAlertes }}
              </div>
              } @if(data.nombreStmcsEsborranys > 0) {
              <div class="esborranys">
                <span>|</span>
                <mat-icon>find_in_page</mat-icon>
                {{ 'CARREGA.ESBORRANY' | translate }}:
                {{ data.nombreStmcsEsborranys }}
              </div>
              }
            </div>
          </div>
        </div>
        <div class="dada-smtc">
          <div class="icon"><mat-icon>folder</mat-icon></div>
          <div>
            <label>{{ 'CARREGA.OPERACIONS_REALITZAES' | translate }}:</label>
            <div class="resultatsFitxer">
              @if(data.nombreStmcsCreats > 0) {
              <div>
                {{ 'CARREGA.ALTES' | translate }}:
                {{ data.nombreStmcsCreats }}
              </div>
              } @if(data.nombreStmcsModificats > 0) {
              <div>
                {{ 'CARREGA.MODIFICATS' | translate }}:
                {{ data.nombreStmcsModificats }}
              </div>
              } @if(data.nombreStmcsOperats > 0) {
              <div>
                {{ 'CARREGA.ALTRES' | translate }}:
                {{ data.nombreStmcsOperats }}
              </div>
              } @if(data.nombreDcGenerats > 0) {
              <div>
                <span>|</span>
                {{ 'CARREGA.CREATS' | translate }}:
                {{ data.nombreDcGenerats }}
              </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div class="botones-carrega">
        <div class="botones-accions">
          <div>
            <button
              class="ctti-button ctti-button-trans"
              (click)="fitxerEviat()"
            >
              <mat-icon>download</mat-icon>
              {{ 'CARREGA.FITXER_ORIGEN' | translate }}
            </button>
          </div>
          <div>
            <button
              class="ctti-button ctti-button-trans"
              (click)="fitxerResultat()"
            >
              <mat-icon>download</mat-icon>
              {{ 'CARREGA.FITXER_RESULTAT' | translate }}
            </button>
          </div>
          <div>
            <button class="ctti-button ctti-button-trans" (click)="dcs()">
              <mat-icon>download</mat-icon>
              {{ 'CARREGA.DCS' | translate }}
            </button>
          </div>
        </div>
        <div>
          <button
            class="ctti-button ctti-button-inv"
            mat-dialog-close
            (click)="closeModal()"
          >
            {{ 'MODAL.TORNAR' | translate }}
          </button>
        </div>
      </div>
    </div>
  `,
})
export class DetallCarrega {
  @Output() closeEvent = new EventEmitter<void>();
  carregaService = inject(CarregaService);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {}

  async fitxerResultat() {
    const fitxer = await lastValueFrom(
      this.carregaService.fileOut(this.data.objectMantId)
    );
    this.descargaPDF(fitxer.dadesResultat, 'text/csv');
  }

  async fitxerEviat() {
    const fitxer = await lastValueFrom(
      this.carregaService.fileIn(this.data.objectMantId)
    );
    this.descargaPDF(fitxer.dadesResultat, 'text/csv');
  }

  async dcs() {
    const fitxer = await lastValueFrom(
      this.carregaService.fileZip(this.data.objectMantId)
    );
    this.descargaPDF(fitxer.dadesResultat, 'application/zip');
  }

  descargaPDF(
    dadesResultat: { contingutBase64: string; nomFitxer: string },
    type: string
  ) {
    if (!dadesResultat) {
      this.dialog.closeAll();
      const callback = () => {
        const dialogRef = this.dialog.open(DetallCarrega, {
          disableClose: true,
          data: this.data,
        });
        dialogRef.componentInstance.closeEvent.subscribe(() => {
          this.dialog.closeAll();
        });
      };
      const dialogRef = this.dialog.open(ErrorModalComponent, {
        disableClose: true,
        data: {
          title: 'MODAL.CAP_FITXER',
          message: '',
          icon: 'error',
          showCancel: false,
          callback,
        },
      });
      dialogRef.componentInstance.closeEvent.subscribe(() => {
        this.dialog.closeAll();
      });
      return;
    }
    const binaryString = atob(dadesResultat.contingutBase64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type });

    const enlace = document.createElement('a');
    enlace.href = URL.createObjectURL(blob);
    enlace.download = dadesResultat.nomFitxer;
    document.body.appendChild(enlace);
    enlace.click();
    document.body.removeChild(enlace);
  }

  closeModal() {
    this.closeEvent.emit();
  }
}
