<main>
  <app-sidebar [admin]="true"></app-sidebar>
  <section [formGroup]="form">
    <p class="tornar" (click)="goBack()" id="tornar">
      <mat-icon size="24px" class="icon">arrow_back_ios</mat-icon>
      {{ "STMC.RETURN" | translate }}
    </p>
    <h1>
      {{
        isEdit ? ("STMC.TITLE_EDIT" | translate) : ("STMC.TITLE" | translate)
      }}
    </h1>
    <div id="mainSection">
      <div class="leftcol">
        <div class="mb">
          <p class="subtitle noMobile">
            {{
              isEdit
                ? ("STMC.SUBTITLE_EDIT" | translate)
                : ("STMC.SUBTITLE" | translate)
            }}
          </p>
          <p class="subtitle mobile">
            {{
              isEdit
                ? ("MOBILE.STMC_EDIT" | translate)
                : ("MOBILE.STMC_NEW" | translate)
            }}
          </p>
        </div>
        <div class="acordio">
          <div class="cap">
            <div class="cap-esq">
              @if(isEdit) {
              <div class="codiCopiar">
                <label
                  >{{ "DC.LABEL_CODI" | translate }}:{{ " "
                  }}<span class="negreta">{{ codi }}</span></label
                >
                <p class="red dflex" (click)="copyCode(codi || '')">
                  @if(copied) {
                  <mat-icon>check</mat-icon
                  ><span class="copiat">{{ "COPIAT" | translate }}</span>
                  }@else {
                  <mat-icon>content_copy</mat-icon>
                  }
                </p>
              </div>
              } @if(sftp && sftp.length) {
              <div class="mes-info" (click)="mesInfo()">
                <mat-icon>add</mat-icon>
                {{ "STMC.INFO_OPEN" | translate }}
              </div>
              }
            </div>
            <div class="grid-2">
              <div class="col">
                <label for="date">{{ "STMC.DATA" | translate }}:</label>
                <input
                  type="date"
                  id="date"
                  class="ctti-input-text enDada"
                  formControlName="date"
                  (change)="updateInput()"
                />
              </div>
              <div class="col">
                <label>{{ "STMC.STATUS" | translate }}:</label>
                <div [class]="estat.toLowerCase().concat(' estat')">
                  {{ textTipus(estat) | translate }}
                  <div [class]="estatDc.toLowerCase()">
                    <mat-icon>{{ iconaTipus(estatDc) }}</mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="page">
            <div
              class="header"
              [ngClass]="activeTab === 1 ? 'active' : ''"
              (click)="setActiveTab(1)"
              (keydown)="handleKeyPress($event, 1)"
              tabindex="0"
              id="tab1"
            >
              <h5 class="title5">{{ "STMC.ACCORDION1" | translate }}</h5>
              <div class="icons-tab">
                <div
                  class="bola"
                  [ngClass]="{
                    validated: isTabValid(1),
                    current: activeTab === 1
                  }"
                >
                  @if(isTabValid(1) && activeTab !== 1) {
                  <mat-icon class="icon">check</mat-icon>
                  }
                </div>
                <mat-icon>{{
                  activeTab === 1 ? "expand_less" : "expand_more"
                }}</mat-icon>
              </div>
            </div>
            @if(activeTab === 1 && currentRoute.includes("transportista")) {
            <div class="content">
              <div class="grid-2">
                <div class="col">
                  <label for="raoSocialCarregador">
                    <span class="red">!{{ " " }}</span>
                    {{ "STMC.COMPANY_NAME" | translate }}:</label
                  >
                  <input
                    type="text"
                    id="raoSocialCarregador"
                    class="ctti-input-text"
                    formControlName="raoSocialCarregador"
                    placeholder="{{
                      'STMC.COMPANY_NAME_PLACEHOLDER' | translate
                    }}"
                    (focus)="onFocus('raoSocial')"
                    (blur)="onBlur('raoSocial')"
                    (input)="changeContextEmpresa($event, 'CARREGADOR')"
                    (change)="updateInput()"
                  />
                  <ul
                    *ngIf="resultados.length > 0 && isFocused.raoSocial"
                    class="contextList"
                  >
                    <li
                      *ngFor="let resultado of resultados"
                      (click)="setFormValue('carregador', resultado)"
                    >
                      {{ resultado.raoSocial }}{{ " - " }}{{ resultado.nif }}
                    </li>
                  </ul>
                </div>

                <div class="col">
                  <label for="nifCarregador">
                    <span class="red">!{{ " " }}</span
                    >{{ "STMC.COMPANY_NIF" | translate }}:</label
                  >
                  <input
                    type="text"
                    id="nifCarregador"
                    class="ctti-input-text"
                    formControlName="nifCarregador"
                    (focus)="onFocus('nif')"
                    (blur)="onBlur('nif')"
                    (input)="changeContextEmpresa($event, 'CARREGADOR')"
                    (change)="updateInput()"
                    maxlength="12"
                    placeholder="{{
                      'STMC.COMPANY_NIF_PLACEHOLDER' | translate
                    }}"
                  />
                  <ul
                    *ngIf="resultados.length > 0 && isFocused.nif"
                    class="contextList"
                  >
                    <li
                      *ngFor="let resultado of resultados"
                      (click)="setFormValue('carregador', resultado)"
                    >
                      {{ resultado.raoSocial }}{{ " - " }}{{ resultado.nif }}
                    </li>
                  </ul>
                </div>

                <div class="col span2">
                  <label for="adrecaCarregador">
                    <span class="red">!{{ " " }}</span
                    >{{ "STMC.ADDRESS" | translate }}:</label
                  >
                  <input
                    type="text"
                    id="adrecaCarregador"
                    class="ctti-input-text"
                    formControlName="adrecaCarregador"
                    placeholder="{{ 'STMC.ADDRESS_PLACEHOLDER' | translate }}"
                    (change)="updateInput()"
                  />
                </div>

                <div class="col">
                  <label for="codiPostalCarregador">
                    <span class="red">!{{ " " }}</span
                    >{{ "STMC.POSTAL_CODE" | translate }}:</label
                  >
                  <input
                    type="text"
                    id="codiPostalCarregador"
                    class="ctti-input-text"
                    formControlName="codiPostalCarregador"
                    placeholder="{{
                      'STMC.POSTAL_CODE_PLACEHOLDER' | translate
                    }}"
                    (change)="updateInput()"
                    maxlength="5"
                  />
                </div>

                <div class="col">
                  <label for="poblacioCarregador">
                    <span class="red">!{{ " " }}</span
                    >{{ "STMC.CITY" | translate }}:</label
                  >
                  <input
                    type="text"
                    id="poblacioCarregador"
                    class="ctti-input-text"
                    formControlName="poblacioCarregador"
                    (focus)="onFocus('poblacio')"
                    (blur)="onBlur('poblacio')"
                    (input)="changeContextPoblacio($event)"
                    placeholder="{{ 'STMC.CITY_PLACEHOLDER' | translate }}"
                    (change)="updateInputPCarregador()"
                  />
                  <ul
                    *ngIf="poblacions.length > 0 && isFocused.poblacio"
                    class="contextList"
                  >
                    <li
                      *ngFor="let poblacio of poblacions"
                      (click)="setFormValue('poblacio', poblacio)"
                    >
                      {{ poblacio.descripcio }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col">
                <label for="observationsC"
                  >{{ "STMC.OBSERVATIONS" | translate }}:</label
                >
                <textarea
                  id="observationsC"
                  class="observations"
                  formControlName="observacionsCarregador"
                  (change)="updateInput()"
                  placeholder="{{
                    'STMC.OBSERVATIONS_PLACEHOLDER' | translate
                  }}"
                ></textarea>
              </div>
            </div>
            } @if(activeTab === 1 && currentRoute.includes("carregador")) {
            <div class="content">
              <div class="grid-2">
                <div class="col">
                  <label for="raoSocialCarregador"
                    >{{ "STMC.COMPANY_NAME" | translate }}:</label
                  >
                  <p>{{ form.get("raoSocialCarregador")?.value || "" }}</p>
                </div>

                <div class="col">
                  <label>{{ "STMC.COMPANY_NIF" | translate }}:</label>
                  <p>{{ form.get("nifCarregador")?.value || "" }}</p>
                </div>

                <div class="col">
                  <label for="adrecaCarregador"
                    >{{ "STMC.ADDRESS" | translate }}:</label
                  >
                  <p>{{ form.get("adrecaCarregador")?.value || "" }}</p>
                </div>

                <div class="col">
                  <label for="codiPostalCarregador"
                    >{{ "STMC.POSTAL_CODE" | translate }}:</label
                  >
                  <p>{{ form.get("codiPostalCarregador")?.value || "" }}</p>
                </div>

                <div class="col">
                  <label for="poblacioCarregador"
                    >{{ "STMC.CITY" | translate }}:</label
                  >
                  <p>{{ form.get("poblacioCarregador")?.value || "" }}</p>
                </div>
              </div>
              <div class="col">
                <label for="observationsC2"
                  >{{ "STMC.OBSERVATIONS" | translate }}:</label
                >
                <textarea
                  id="observationsC2"
                  class="observations"
                  formControlName="observacionsCarregador"
                  (change)="updateInput()"
                  placeholder="{{
                    'STMC.OBSERVATIONS_PLACEHOLDER' | translate
                  }}"
                ></textarea>
              </div>
            </div>
            }
          </div>

          <div class="page">
            <div
              class="header"
              [ngClass]="activeTab === 2 ? 'active' : ''"
              (click)="setActiveTab(2)"
              (keydown)="handleKeyPress($event, 2)"
              tabindex="0"
              id="tab2"
            >
              <h5 class="title5">{{ "STMC.ACCORDION2" | translate }}</h5>
              <div class="icons-tab">
                <div
                  class="bola"
                  [ngClass]="{
                    validated: isTabValid(2),
                    current: activeTab === 2
                  }"
                >
                  @if(isTabValid(2) && activeTab !== 2) {
                  <mat-icon class="icon">check</mat-icon>
                  }
                </div>
                <mat-icon>{{
                  activeTab === 2 ? "expand_less" : "expand_more"
                }}</mat-icon>
              </div>
            </div>
            @if(activeTab === 2 && currentRoute.includes("carregador")) {
            <div class="content">
              <div class="titolHabituals">
                <h5>{{ "STMC.TRANS_HABITUAL" | translate }}</h5>
                <p (click)="openHabitual('transportista')">
                  <span class="red">
                    <mat-icon>visibility</mat-icon>
                    {{ "STMC.VEURE_TOTS" | translate }}
                  </span>
                </p>
              </div>
              <div class="cardsHabituals">
                @for(trans of transportistes; track trans;) {
                <div
                  (click)="selectTransportista(trans)"
                  class="habitual"
                  [class]="isSelectedTrans(trans)"
                >
                  {{ trans.alias }}
                </div>
                }
              </div>
              <div class="divider"></div>
              <div class="grid-2">
                <div class="col">
                  <label for="raoSocialTransportista"
                    ><span class="red">!{{ " " }}</span
                    >{{ "STMC.COMPANY_NAME" | translate }}:</label
                  >
                  <input
                    type="text"
                    id="raoSocialTransportista"
                    class="ctti-input-text"
                    formControlName="raoSocialTransportista"
                    placeholder="{{
                      'STMC.COMPANY_NAME_PLACEHOLDER' | translate
                    }}"
                    (focus)="onFocus('raoSocialTrans')"
                    (blur)="onBlur('raoSocialTrans')"
                    (input)="changeContextEmpresa($event, 'TRANSPORTISTA')"
                    (change)="updateInput()"
                  />
                  <ul
                    *ngIf="resultados.length > 0 && isFocused.raoSocialTrans"
                    class="contextList"
                  >
                    <li
                      *ngFor="let resultado of resultados"
                      (click)="setFormValue('transportista', resultado)"
                    >
                      {{ resultado.raoSocial }}{{ " - " }}{{ resultado.nif }}
                    </li>
                  </ul>
                </div>

                <div class="col">
                  <label for="nifTransportista"
                    ><span class="red">!{{ " " }}</span
                    >{{ "STMC.COMPANY_NIF" | translate }}:</label
                  >
                  <input
                    type="text"
                    id="nifTransportista"
                    class="ctti-input-text"
                    formControlName="nifTransportista"
                    placeholder="{{
                      'STMC.COMPANY_NAME_PLACEHOLDER' | translate
                    }}"
                    maxlength="12"
                    (focus)="onFocus('nifTrans')"
                    (blur)="onBlur('nifTrans')"
                    (input)="changeContextEmpresa($event, 'TRANSPORTISTA')"
                    (change)="updateInput()"
                  />
                  <ul
                    *ngIf="resultados.length > 0 && isFocused.nifTrans"
                    class="contextList"
                  >
                    <li
                      *ngFor="let resultado of resultados"
                      (click)="setFormValue('transportista', resultado)"
                    >
                      {{ resultado.raoSocial }}{{ " - " }}{{ resultado.nif }}
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col">
                <label for="observations"
                  >{{ "STMC.OBSERVATIONS" | translate }}:</label
                >
                <textarea
                  id="observations"
                  class="observations"
                  formControlName="observacionsTransportista"
                  (change)="updateInput()"
                  placeholder="{{
                    'STMC.OBSERVATIONS_PLACEHOLDER' | translate
                  }}"
                ></textarea>
              </div>
              <button class="botoAfegir" (click)="addTransportista()">
                <img
                  src="../../../assets/images/favorite.png"
                  alt="nou habitual"
                />
                {{ "STMC.ADD_HABITUAL" | translate }}
              </button>
            </div>
            } @if(activeTab === 2 && currentRoute.includes("transportista")) {
            <div class="content">
              <div class="grid-2">
                <div class="col">
                  <label
                    ><span class="red">!{{ " " }}</span
                    >{{ "STMC.COMPANY_NAME" | translate }}</label
                  >
                  <p>{{ form.get("raoSocialTransportista")?.value || "" }}</p>
                </div>

                <div class="col">
                  <label
                    ><span class="red">!{{ " " }}</span
                    >{{ "STMC.COMPANY_NIF" | translate }}</label
                  >
                  <p>{{ form.get("nifTransportista")?.value || "" }}</p>
                </div>
              </div>

              <div class="col">
                <label>{{ "STMC.OBSERVATIONS" | translate }}:</label>
                <textarea
                  class="observations"
                  formControlName="observacionsTransportista"
                  (change)="updateInput()"
                  placeholder="{{
                    'STMC.OBSERVATIONS_PLACEHOLDER' | translate
                  }}"
                ></textarea>
              </div>
            </div>
            }
          </div>
          <div class="page">
            <div
              class="header"
              [ngClass]="activeTab === 3 ? 'active' : ''"
              (click)="setActiveTab(3)"
              (keydown)="handleKeyPress($event, 3)"
              tabindex="0"
              id="tab3"
            >
              <h5 class="title5">{{ "STMC.ACCORDION3" | translate }}</h5>
              <div class="icons-tab">
                <div
                  class="bola"
                  [ngClass]="{
                    validated: isTabValid(3),
                    current: activeTab === 3
                  }"
                >
                  @if(isTabValid(3) && activeTab !== 3) {
                  <mat-icon class="icon">check</mat-icon>
                  }
                </div>
                <mat-icon>{{
                  activeTab === 3 ? "expand_less" : "expand_more"
                }}</mat-icon>
              </div>
            </div>
            @if(activeTab === 3) {
            <div class="content">
              <div class="titolHabituals">
                <h5>{{ "STMC.RUTA_HABITUAL" | translate }}</h5>
                <p (click)="openHabitual('ruta')">
                  <span class="red">
                    <mat-icon>visibility</mat-icon>
                    {{ "STMC.VEURE_TOTS" | translate }}
                  </span>
                </p>
              </div>
              <div class="cardsHabituals">
                @for(ruta of rutes; track ruta;) {
                <div
                  (click)="selectRuta(ruta)"
                  class="habitual"
                  [class]="isSelectedRuta(ruta)"
                >
                  {{ ruta.alias }}
                </div>
                }
              </div>
              <div class="divider"></div>
              <h5>{{ "STMC.ORIGIN" | translate }}</h5>
              <div class="col">
                <label for="originAddress"
                  >{{ "STMC.ADDRESS" | translate }}:</label
                >
                <input
                  type="text"
                  id="originAddress"
                  class="ctti-input-text"
                  formControlName="originAddress"
                  placeholder="{{ 'STMC.ADDRESS_PLACEHOLDER' | translate }}"
                  (change)="updateInput()"
                />
              </div>
              <div class="grid-2">
                <div class="col">
                  <label for="originPostalCode"
                    >{{ "STMC.POSTAL_CODE" | translate }}:</label
                  >
                  <input
                    type="text"
                    id="originPostalCode"
                    class="ctti-input-text"
                    formControlName="originPostalCode"
                    placeholder="{{
                      'STMC.POSTAL_CODE_PLACEHOLDER' | translate
                    }}"
                    (change)="updateInput()"
                    maxlength="5"
                  />
                </div>

                <div class="col">
                  <label for="originProvince"
                    >{{ "STMC.PROVINCE" | translate }}:</label
                  >
                  <mat-select
                    id="originProvince"
                    formControlName="originProvince"
                    #dropdown
                    disableOptionCentering
                    disableRipple
                    placeholder="Selecciona una opció"
                    class="ctti-dropdown desplegable"
                    panelClass="ctti-dropdown-panel"
                    [ngClass]="form.value.originProvince ? 'enDada' : ''"
                    (change)="updateProvince($event, 'origin')"
                  >
                    <mat-option value="" selected>{{
                      "STMC.PROVINCE_PLACEHOLDER" | translate
                    }}</mat-option>
                    <mat-option
                      *ngFor="let province of provinces"
                      [value]="province.label"
                    >
                      {{ province.label }}
                    </mat-option>
                  </mat-select>
                </div>

                <div class="col">
                  <label for="originCity"
                    ><span class="red">!{{ " " }}</span
                    >{{ "STMC.CITY" | translate }}:</label
                  >
                  <input
                    type="text"
                    id="originCity"
                    class="ctti-input-text"
                    formControlName="originCity"
                    (focus)="onFocus('originCity')"
                    (blur)="onBlur('originCity')"
                    (input)="
                      changeContextPoblacio($event, form.value.originProvince)
                    "
                    placeholder="{{ 'STMC.CITY_PLACEHOLDER' | translate }}"
                    (change)="updateInputPOrigen()"
                  />
                  <ul
                    *ngIf="poblacions.length > 0 && isFocused.originCity"
                    class="contextList"
                  >
                    <li
                      *ngFor="let poblacio of poblacions"
                      (click)="setFormValue('originCity', poblacio)"
                    >
                      {{ poblacio.descripcio }}
                    </li>
                  </ul>
                </div>
              </div>
              <h5>{{ "STMC.DESTINATION" | translate }}</h5>
              <div class="col">
                <label for="destinationAddress"
                  >{{ "STMC.ADDRESS" | translate }}:</label
                >
                <input
                  type="text"
                  id="destinationAddress"
                  class="ctti-input-text"
                  formControlName="destinationAddress"
                  placeholder="{{ 'STMC.ADDRESS_PLACEHOLDER' | translate }}"
                  (change)="updateInput()"
                />
              </div>
              <div class="grid-2">
                <div class="col">
                  <label for="destinationPostalCode"
                    >{{ "STMC.POSTAL_CODE" | translate }}:</label
                  >
                  <input
                    type="text"
                    id="destinationPostalCode"
                    class="ctti-input-text"
                    formControlName="destinationPostalCode"
                    placeholder="{{
                      'STMC.POSTAL_CODE_PLACEHOLDER' | translate
                    }}"
                    (change)="updateInput()"
                    maxlength="5"
                  />
                </div>
                <div class="col">
                  <label for="destinationProvince"
                    >{{ "STMC.PROVINCE" | translate }}:</label
                  >
                  <mat-select
                    id="destinationProvince"
                    formControlName="destinationProvince"
                    placeholder="Selecciona una opció"
                    #dropdown
                    disableOptionCentering
                    disableRipple
                    class="ctti-dropdown desplegable"
                    [ngClass]="form.value.destinationProvince ? 'enDada' : ''"
                    panelClass="ctti-dropdown-panel"
                    (change)="updateProvince($event, 'destination')"
                  >
                    <mat-option value="" selected>{{
                      "STMC.PROVINCE_PLACEHOLDER" | translate
                    }}</mat-option>
                    <mat-option
                      *ngFor="let province of provinces"
                      [value]="province.label"
                    >
                      {{ province.label }}
                    </mat-option>
                  </mat-select>
                </div>
                <div class="col">
                  <label for="destinationCity"
                    ><span class="red">!{{ " " }}</span
                    >{{ "STMC.CITY" | translate }}:</label
                  >
                  <input
                    type="text"
                    id="destinationCity"
                    class="ctti-input-text"
                    formControlName="destinationCity"
                    (focus)="onFocus('destinationCity')"
                    (blur)="onBlur('destinationCity')"
                    (input)="
                      changeContextPoblacio(
                        $event,
                        form.value.destinationProvince
                      )
                    "
                    placeholder="{{ 'STMC.CITY_PLACEHOLDER' | translate }}"
                    (change)="updateInputPDesti()"
                  />
                  <ul
                    *ngIf="poblacions.length > 0 && isFocused.destinationCity"
                    class="contextList"
                  >
                    <li
                      *ngFor="let poblacio of poblacions"
                      (click)="setFormValue('destinationCity', poblacio)"
                    >
                      {{ poblacio.descripcio }}
                    </li>
                  </ul>
                </div>
              </div>
              <button class="botoAfegir" (click)="addRuta()">
                <img
                  src="../../../assets/images/favorite.png"
                  alt="nou habitual"
                />
                {{ "STMC.ADD_HABITUAL" | translate }}
              </button>
            </div>
            }
          </div>

          <div class="page">
            <div
              class="header"
              [ngClass]="activeTab === 4 ? 'active' : ''"
              (click)="setActiveTab(4)"
              (keydown)="handleKeyPress($event, 4)"
              tabindex="0"
              id="tab4"
            >
              <h5 class="title5">{{ "STMC.ACCORDION4" | translate }}</h5>
              <div class="icons-tab">
                <div
                  class="bola"
                  [ngClass]="{
                    validated: isTabValid(4),
                    current: activeTab === 4
                  }"
                >
                  @if(isTabValid(4) && activeTab !== 4) {
                  <mat-icon class="icon">check</mat-icon>
                  }
                </div>
                <mat-icon>{{
                  activeTab === 4 ? "expand_less" : "expand_more"
                }}</mat-icon>
              </div>
            </div>
            @if(activeTab === 4) {
            <div class="content">
              <div class="titolHabituals">
                <h5>{{ "STMC.VEHICLE_DATA" | translate }}</h5>
                <p (click)="openHabitual('vehicle')">
                  <span class="red">
                    <mat-icon>visibility</mat-icon>
                    {{ "STMC.VEURE_TOTS" | translate }}
                  </span>
                </p>
              </div>
              <div class="cardsHabituals">
                @for(item of vehicles; track item;) {
                <div
                  (click)="selectVehicle(item)"
                  class="habitual"
                  [class]="isSelectedVehicle(item)"
                >
                  {{ item.alias }}
                  <mat-icon>{{
                    item.tipusVehicle === "REMOLC"
                      ? "rv_hookup"
                      : "directions_car"
                  }}</mat-icon>
                </div>
                }
              </div>
              <div class="divider"></div>

              <div class="matricula">
                <div class="col">
                  <label for="vehicleLicensePlate"
                    ><span class="red">!{{ " " }}</span
                    >{{ "STMC.VEHICLE_LICENSE_PLATE" | translate }}:</label
                  >
                  <input
                    type="text"
                    id="vehicleLicensePlate"
                    class="ctti-input-text"
                    formControlName="vehicleLicensePlate"
                    placeholder="{{
                      'STMC.VEHICLE_LICENSE_PLATE_PLACEHOLDER' | translate
                    }}"
                    (change)="updateInput()"
                    maxlength="10"
                  />
                </div>
                <button class="botoAfegir" (click)="addVehicle('TRACTOR')">
                  <img
                    src="../../../assets/images/favorite.png"
                    alt="Nou habitual"
                  />
                  {{ "STMC.ADD_HABITUAL" | translate }}
                </button>
              </div>
              <div class="matricula">
                <div class="col">
                  <label for="trailerLicensePlate"
                    >{{ "STMC.TRAILER_LICENSE_PLATE" | translate }}:</label
                  >
                  <input
                    type="text"
                    id="trailerLicensePlate"
                    class="ctti-input-text"
                    formControlName="trailerLicensePlate"
                    maxlength="10"
                    placeholder="{{
                      'STMC.TRAILER_LICENSE_PLATE_PLACEHOLDER' | translate
                    }}"
                    (change)="updateInput()"
                  />
                </div>
                <button class="botoAfegir" (click)="addVehicle('REMOLC')">
                  <img
                    src="../../../assets/images/favorite.png"
                    alt="nou habitual"
                  />
                  {{ "STMC.ADD_HABITUAL" | translate }}
                </button>
              </div>
              <br />
              @if(mostrarSubstitutes()) {
              <div class="matricula">
                <h5>{{ "STMC.VEHICLE_SUB_DATA" | translate }}</h5>
                <button class="botoAfegir" (click)="addMatriculaSub()">
                  {{ "STMC.ADD_HABITUAL_SUB" | translate }}
                </button>
              </div>
              <div class="grid-2 substituts">
                @if(matricules.matriculaTractorSubs1) {
                <div>
                  <label for="matriculaTractorSubs1"
                    >{{ "STMC.SUBVEHICLE_LICENSE_PLATE" | translate }} 1:</label
                  >
                  <p>{{ matricules.matriculaTractorSubs1 }}</p>
                </div>
                } @if(matricules.matriculaTractorSubs2) {
                <div>
                  <label for="matriculaTractorSubs2"
                    >{{ "STMC.SUBVEHICLE_LICENSE_PLATE" | translate }} 2:</label
                  >
                  <p>{{ matricules.matriculaTractorSubs2 }}</p>
                </div>
                } @if(matricules.matriculaRemolcSubs1) {
                <div>
                  <label for="matriculaTractorSubs1"
                    >{{ "STMC.SUBTRAILER_LICENSE_PLATE" | translate }} 1:</label
                  >
                  <p>{{ matricules.matriculaRemolcSubs1 }}</p>
                </div>
                } @if(matricules.matriculaRemolcSubs2) {
                <div>
                  <label for="matriculaTractorSubs2"
                    >{{ "STMC.SUBTRAILER_LICENSE_PLATE" | translate }} 2:</label
                  >
                  <p>{{ matricules.matriculaRemolcSubs2 }}</p>
                </div>
                }
              </div>
              }
            </div>
            }
          </div>

          <div class="page">
            <div
              class="header"
              [ngClass]="activeTab === 5 ? 'active' : ''"
              (click)="setActiveTab(5)"
              (keydown)="handleKeyPress($event, 5)"
              tabindex="0"
              id="tab5"
            >
              <h5 class="title5">{{ "STMC.ACCORDION5" | translate }}</h5>
              <div class="icons-tab">
                <div
                  class="bola"
                  [ngClass]="{
                    validated: isTabValid(5),
                    current: activeTab === 5
                  }"
                >
                  @if(isTabValid(5) && activeTab !== 5) {
                  <mat-icon class="icon">check</mat-icon>
                  }
                </div>
                <mat-icon>{{
                  activeTab === 5 ? "expand_less" : "expand_more"
                }}</mat-icon>
              </div>
            </div>
            @if(activeTab === 5) {
            <div class="content">
              <h5>{{ "STMC.GOODS_DATA" | translate }}</h5>
              <div class="grid-2">
                <div class="col">
                  <label for="goodsType"
                    ><span class="red">!{{ " " }}</span
                    >{{ "STMC.GOODS_TYPE" | translate }}:</label
                  >
                  <mat-select
                    id="goodsType"
                    formControlName="goodsType"
                    #dropdown
                    disableOptionCentering
                    disableRipple
                    class="ctti-dropdown desplegable"
                    [ngClass]="form.value.goodsType ? 'enDada' : ''"
                    panelClass="ctti-dropdown-panel"
                    (change)="updateInput()"
                  >
                    <mat-option value="" selected>{{
                      "STMC.SELECCIONA_MERCADERIA" | translate
                    }}</mat-option>
                    @for(mercaderia of mercaderies; track mercaderia){
                    <mat-option [value]="mercaderia.codiElement">
                      <span [ngClass]="mercaderia.esborrada ? 'red' : ''">{{
                        mercaderia.descripcio
                      }}</span>
                    </mat-option>
                    }
                  </mat-select>
                </div>
                <div class="col">
                  <label for="goodsWeight"
                    >{{ "STMC.GOODS_WEIGHT" | translate }}:</label
                  >
                  <input
                    type="text"
                    id="goodsWeight"
                    class="ctti-input-text"
                    formControlName="goodsWeight"
                    placeholder="{{
                      'STMC.GOODS_WEIGHT_PLACEHOLDER' | translate
                    }}"
                    (input)="checkLletres()"
                    (change)="updateInput()"
                  />
                </div>

                <div class="col">
                  <label>{{ "STMC.REQUEREIX" | translate }}:</label>
                  <div class="radio-buttons">
                    <label>
                      <input
                        type="radio"
                        formControlName="requereixAutoritzacio"
                        [value]="true"
                        (change)="updateRadio()"
                      />
                      {{ "STMC.SI" | translate }}
                    </label>
                    <label>
                      <input
                        type="radio"
                        formControlName="requereixAutoritzacio"
                        [value]="false"
                        (change)="updateRadio()"
                      />
                      {{ "STMC.NO" | translate }}
                    </label>
                  </div>
                </div>
                <div class="col">
                  <label for="numeroAutorizacio"
                    >{{ "STMC.NUMERO_AUT" | translate }}:</label
                  >
                  @if(form.value.requereixAutoritzacio) {
                  <input
                    type="text"
                    id="numeroAutorizacio"
                    class="ctti-input-text"
                    formControlName="numeroAutorizacio"
                    placeholder="{{
                      'STMC.NUMERO_AUT_PLACEHOLDER' | translate
                    }}"
                    (change)="updateInput()"
                    maxlength="15"
                  />
                  } @else {
                  <input
                    type="text"
                    id="numeroAutorizacio"
                    class="ctti-input-text"
                    placeholder="{{
                      'STMC.NUMERO_AUT_PLACEHOLDER' | translate
                    }}"
                    disabled
                    [disabled]="true"
                    maxlength="15"
                  />
                  }
                </div>
              </div>
              <div class="col">
                <label for="goodsDescription"
                  >{{ "STMC.GOODS_DESCRIPTION" | translate }}:</label
                >
                <textarea
                  class="observations"
                  id="goodsDescription"
                  formControlName="goodsDescription"
                  placeholder="{{
                    'STMC.GOODS_DESCRIPTION_PLACEHOLDER' | translate
                  }}"
                ></textarea>
              </div>
            </div>
            }
          </div>

          <div class="page">
            <div
              class="header"
              [ngClass]="activeTab === 6 ? 'active' : ''"
              (click)="setActiveTab(6)"
              (keydown)="handleKeyPress($event, 6)"
              tabindex="0"
              id="tab6"
            >
              <h5 class="title5">{{ "STMC.ACCORDION6" | translate }}</h5>
              <div class="icons-tab">
                <mat-icon>{{
                  activeTab === 6 ? "expand_less" : "expand_more"
                }}</mat-icon>
              </div>
            </div>
            @if(activeTab === 6) {
            <div class="content">
              <div class="col">
                <h5 class="title5">
                  {{ "STMC.ATTACHED_FILES_TITLE" | translate }}
                </h5>
                <p>{{ "STMC.ATTACHED_FILES_SUBTITLE" | translate }}</p>

                <button class="fileinput" (click)="fileInputClick()">
                  <mat-icon>description</mat-icon>
                  {{ "STMC.ATTACHED_FILES" | translate }}
                </button>
                <div class="fitxers">
                  @for(file of uploadedFiles; track file; let idx = $index) {
                  @if(file.status !== "delete") {
                  <div class="fitxer">
                    <div class="lane">
                      @if(file.url?.includes("base64")) {
                      <span class="clickable" (click)="openFile(file.url)">{{
                        file.name
                      }}</span>
                      } @else {
                      <span>{{ file.name }}</span>
                      }
                      <div (click)="removeFile(idx)">
                        <mat-icon>delete</mat-icon>
                      </div>
                    </div>
                    <div *ngIf="file.url">
                      <img
                        [src]="file.url"
                        [alt]="file.name"
                        width="100"
                      />
                    </div>
                  </div>
                  <br/>
                  } }
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
      <div class="rightcol">
        <div class="saveBox">
          @if(saving){
          <button class="desant">
            {{ "STMC.SAVING" | translate }}
          </button>
          } @else {
          <button class="valid" (click)="saveSmtc()">
            <mat-icon>save</mat-icon>
            {{ "STMC.SAVE" | translate }}
          </button>
          } @if(estatDc === 'DC_CREAT') {
          <button class="generar" (click)="dcActual()">
            <mat-icon>visibility</mat-icon>
            {{ "STMC.DC_ACTUAL" | translate }}
          </button>
          } @else {
          <button class="generar" (click)="dcPrevisualitzacio()">
            <mat-icon>task_alt</mat-icon>
            {{ "STMC.PREV" | translate }}
          </button>
          }
        </div>

        <div class="seccions">
          <h5 class="title5">{{ "STMC.SECTIONS" | translate }}</h5>
          <div class="sections">
            <div class="lane">
              <div
                class="bola"
                [ngClass]="{
                  validated: isTabValid(1),
                  current: activeTab === 1
                }"
              >
                @if(isTabValid(1) && activeTab !== 1) {
                <mat-icon class="icon">check</mat-icon>
                }
              </div>
              <p [ngClass]="{ current: activeTab === 1 }">
                {{ "STMC.SECTION1" | translate }}
              </p>
            </div>
            <div class="lane">
              <div
                class="bola"
                [ngClass]="{
                  validated: isTabValid(2),
                  current: activeTab === 2
                }"
              >
                @if(isTabValid(2) && activeTab !== 2) {
                <mat-icon class="icon">check</mat-icon>
                }
              </div>
              <p [ngClass]="{ current: activeTab === 2 }">
                {{ "STMC.SECTION2" | translate }}
              </p>
            </div>
            <div class="lane">
              <div
                class="bola"
                [ngClass]="{
                  validated: isTabValid(3),
                  current: activeTab === 3
                }"
              >
                @if(isTabValid(3) && activeTab !== 3) {
                <mat-icon class="icon">check</mat-icon>
                }
              </div>
              <p [ngClass]="{ current: activeTab === 3 }">
                {{ "STMC.SECTION3" | translate }}
              </p>
            </div>
            <div class="lane">
              <div
                class="bola"
                [ngClass]="{
                  validated: isTabValid(4),
                  current: activeTab === 4
                }"
              >
                @if(isTabValid(4) && activeTab !== 4) {
                <mat-icon class="icon">check</mat-icon>
                }
              </div>
              <p [ngClass]="{ current: activeTab === 4 }">
                {{ "STMC.SECTION4" | translate }}
              </p>
            </div>
            <div class="lane">
              <div
                class="bola"
                [ngClass]="{
                  validated: isTabValid(5),
                  current: activeTab === 5
                }"
              >
                @if(isTabValid(5) && activeTab !== 5) {
                <mat-icon class="icon">check</mat-icon>
                }
              </div>
              <p [ngClass]="{ current: activeTab === 5 }">
                {{ "STMC.SECTION5" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <input
      #fileInput
      type="file"
      id="attachedFiles"
      formControlName="attachedFiles"
      (change)="onFileSelected($event)"
    />
  </section>
</main>
