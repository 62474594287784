<header>
  <div class="item">
    <img id="logo" src="../../../assets/images/Vector.png" alt="Logo Gencat"/>
    <h4 class="ctti-h4 border-left">PadelT</h4>
  </div>
  <div class="item desktop">
    <button [matMenuTriggerFor]="item" aria-labelledby="language-label">
      <span id="language-label">{{ idioma }}</span>
      <mat-icon>expand_more</mat-icon>
    </button>
    
    <mat-menu #item="matMenu" yPosition="below">
      <ul class="desplegable">
        @for(item of idiomes; track item) {
        <li (click)="canviarIdioma(item.code)">{{ item.name }}</li>
        }
      </ul>
    </mat-menu>
    @if(logedIn) {
    <div class="login">
      @if(image.usuariFitxer64) {
      <img
        [src]="image.usuariFitxer64"
        class="imatgeHeader"
        (click)="goprofile()"
        alt="perfil"
      />
      } @else {
      <mat-icon class="profile-icon" (click)="goprofile()"
        >account_circle</mat-icon
      >
      }
      <div class="logedUserData">
        <p class="nom">
          {{
            this.userData.nom +
              " " +
              this.userData.cognom +
              " " +
              (this.userData.secCognom || "")
          }}
        </p>
        <div class="empresaDrop">
          <button [matMenuTriggerFor]="empresaDrop" aria-labelledby="empresa-label">
            <span id="empresa-label">{{ empresaRao(empresa.raoSocial) }}</span>
            <mat-icon>expand_more</mat-icon>
          </button>
        </div>
        <mat-menu #empresaDrop="matMenu" yPosition="below">
          <ul class="desplegable empresas-desktop">
            @for(item of empreses; track item) {
            <li
              (click)="canviarEmpresa(item)"
              [class]="
                item.raoSocial === empresa.raoSocial ? 'empresaEnUs' : ''
              "
            >
              @if(item.raoSocial) {
              <label>{{ item.raoSocial }}</label>
              } @else {
              <label>{{ item.nifEmpresa }}</label>
              } @if(item.raoSocial === empresa.raoSocial) {
              <div class="red"><mat-icon>check</mat-icon></div>
              } @if(!item.raoSocial) {
              <div class="red"><mat-icon>add_business</mat-icon></div>
              }
            </li>
            }
          </ul>
        </mat-menu>
      </div>
      <mat-icon class="logout-icon" (click)="logout()"
        >power_settings_new</mat-icon
      >
    </div>
    } @else {
    <div class="login">
      <mat-icon class="profile-icon" (click)="goprofile()"
        >account_circle</mat-icon
      >
      <p>Accés / Login</p>
    </div>
    }
  </div>
  <div class="item mobile">
    <button [matMenuTriggerFor]="profile" aria-label="Perfil">
      @if(image.usuariFitxer64) {
      <img [src]="image.usuariFitxer64" class="imatgeHeader" alt="perfil"/>
      } @else {
      <mat-icon class="profile-icon">account_circle</mat-icon>
      }
    </button>
    <mat-menu #profile="matMenu" yPosition="below" id="matMenuMobile">
      <div class="desplegable-mobile" (click)="$event.stopPropagation()">
        <p class="negreta flecha">
          <span (click)="goprofile()"
            >{{
              this.userData.nom +
                " " +
                this.userData.cognom +
                " " +
                this.userData.secCognom
            }}
          </span>
          <mat-icon (click)="desplegarEmpresa()">{{
            desplegadoEmpresa ? "arrow_drop_down" : "arrow_drop_up"
          }}</mat-icon>
        </p>
        <ul class="desplegable">
          @if(desplegadoEmpresa) { @for(item of empreses; track item) {
          <li class="flecha" (click)="canviarEmpresa(item)">
            @if(item.raoSocial) {
            <span [class]="item.raoSocial === empresa.raoSocial ? 'negreta' : ''">{{ item.raoSocial }}</span>
            } @else {
            {{ item.nifEmpresa }}
            } @if(item.raoSocial === empresa.raoSocial) {
            <mat-icon>check</mat-icon>
            } @if(!item.raoSocial) {
            <mat-icon>add_business</mat-icon>
            }
          </li>
          } }
        </ul>
        <p class="negreta flecha" (click)="desplegarIdioma()">
          <span>Idioma</span>
          <mat-icon>{{
            desplegadoIdioma ? "arrow_drop_down" : "arrow_drop_up"
          }}</mat-icon>
        </p>
        <ul class="desplegable">
          @if(desplegadoIdioma) { @for(item of idiomes; track item) {
          <li (click)="canviarIdioma(item.code)" class="flecha">
            <span [class]="item.code === idioma ? 'negreta' : ''">{{ item.name }}</span>
            @if(item.code === idioma) {
            <mat-icon>check</mat-icon>
            }
          </li>
          } }
        </ul>
        <p class="desconnexio" (click)="logout()">
          Desconnexió
          <span class="red"><mat-icon>power_settings_new</mat-icon></span>
        </p>
      </div>
    </mat-menu>
    @if(hamburguesa) {
    <button aria-label="Menu desplegable">
      <mat-icon class="icon" (click)="openSidebar()">{{
        iconMenu
      }}</mat-icon>
    </button>
    }
  </div>
</header>
<app-sidepanel #panel [formGroup]="formData" ariaLabel="Registre d'empresa">
  @if(siedpanelOpen) {
  <div class="top-row">
    <h4 class="title4">{{ "REGISTRE.REGISTRE_EMPRESA" | translate }}</h4>
    <div class="botones">
      <button class="trans" (click)="panel.close()">
        {{ "MODAL.CANCEL" | translate }}
      </button>
      <button class="filled" (click)="newEmpresa()" [disabled]="clicked" [ngClass]="clicked ? 'disabled' : ''">
        <mat-icon>save</mat-icon>
        {{ "MODAL.SAVE" | translate }}
      </button>
    </div>
  </div>
  <hr />
  <p>{{ "REGISTRE.REGISTRE_EMPRESA_SUB" | translate }}</p>
  <h5 class="title5">
    <span>{{ "REGISTRE.COMPANY_INFO_TITLE" | translate }}</span>
    <mat-icon *ngIf="!isWeb" (click)="setSeccio(0)">{{
      seccions[0] ? "keyboard_arrow_up" : "keyboard_arrow_down"
    }}</mat-icon>
  </h5>
  <div *ngIf="isWeb || seccions[0]" class="seccio">
    <div class="col">
      <label for="raoSocialRE"
        ><span class="red">*</span
        >{{ "REGISTRE.COMPANY_NAME_LABEL" | translate }}:</label
      >
      <input
        matInput
        cdkFocusInitial
        type="text"
        id="raoSocialRE"
        formControlName="raoSocial"
        placeholder="{{ 'REGISTRE.COMPANY_NAME_PLACEHOLDER' | translate }}"
        class="ctti-input-text"
      />
    </div>
    <div class="row">
      <div class="col">
        <label for="nifEmpresaRE"
          ><span class="red">*</span
          >{{ "REGISTRE.COMPANY_NIF_LABEL" | translate }}:</label
        >
        <input
          type="text"
          id="nifEmpresaRE"
          formControlName="nifEmpresa"
          placeholder="{{ 'REGISTRE.COMPANY_NIF_PLACEHOLDER' | translate }}"
          class="ctti-input-text"
          [disabled]="true"
        />
      </div>
      <div class="col">
        <label><span class="red">*</span
          >{{ "REGISTRE.ROLE_LABEL" | translate }}:</label>
        <div class="rols">
          <mat-checkbox
            id="carregadorRE"
            [checked]="isChecked('CARR')"
            (change)="onRoleChange('CARR')"
            ><span class="marge">{{
              "REGISTRE.ROLE_LOADER" | translate
            }}</span></mat-checkbox
          >
          <mat-checkbox
            id="transportistaRE"
            [checked]="isChecked('TRAN')"
            (change)="onRoleChange('TRAN')"
            ><span class="marge">{{
              "REGISTRE.ROLE_TRANSPORTER" | translate
            }}</span></mat-checkbox
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label for="adrecaElectronicaEmpresaRE"
          ><span class="red">*</span
          >{{ "REGISTRE.PROFESSIONAL_EMAIL_LABEL" | translate }}:</label
        >
        <input
          matInput
          type="text"
          id="adrecaElectronicaEmpresaRE"
          formControlName="adrecaElectronicaEmpresa"
          placeholder="{{
            'REGISTRE.PROFESSIONAL_EMAIL_PLACEHOLDER' | translate
          }}"
          class="ctti-input-text"
        />
      </div>
      <div class="col">
        <label for="telefonEmpresaRE"
          ><span class="red">*</span
          >{{ "REGISTRE.PROFESSIONAL_PHONE_LABEL" | translate }}:</label
        >
        <input
          matInput
          type="text"
          id="telefonEmpresaRE"
          formControlName="telefonEmpresa"
          placeholder="{{
            'REGISTRE.PROFESSIONAL_PHONE_PLACEHOLDER' | translate
          }}"
          class="ctti-input-text"
          maxlength="12"
        />
      </div>
    </div> 
  </div>

  <h5 class="title5">
    <span>{{ "REGISTRE.COMPANY_ADDRESS_TITLE" | translate }}</span>
    <mat-icon *ngIf="!isWeb" (click)="setSeccio(1)">{{
      seccions[1] ? "keyboard_arrow_up" : "keyboard_arrow_down"
    }}</mat-icon>
  </h5>
  <div *ngIf="isWeb || seccions[1]" class="seccio">
    <div class="row">
      <div class="col">
        <label for="adrecaEmpresaRE">
          <span class="red">*</span>
          {{ "REGISTRE.COMPANY_ADDRESS_LABEL" | translate }}:</label
        >
        <input
          matInput
          type="text"
          id="adrecaEmpresaRE"
          formControlName="adrecaEmpresa"
          placeholder="{{ 'REGISTRE.COMPANY_ADDRESS_PLACEHOLDER' | translate }}"
          class="ctti-input-text"
          required
        />
      </div>
      <div class="col">
        <label for="codiPostalEmpresaRE"
          ><span class="red">*</span
          >{{ "REGISTRE.COMPANY_ZIP_LABEL" | translate }}:</label
        >
        <input
          matInput
          type="text"
          id="codiPostalEmpresaRE"
          formControlName="codiPostalEmpresa"
          placeholder="{{ 'REGISTRE.COMPANY_ZIP_PLACEHOLDER' | translate }}"
          class="ctti-input-text"
          maxlength="5"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label for="poblacioRE"
          ><span class="red">*</span
          >{{ "REGISTRE.COMPANY_CITY_LABEL" | translate }}:</label
        >
        <input
          type="text"
          id="poblacioRE"
          class="ctti-input-text"
          formControlName="poblacio"
          (focus)="onFocus()"
          (blur)="onBlur()"
          (input)="changeContextPoblacio($event)"
          placeholder="{{ 'STMC.CITY_PLACEHOLDER' | translate }}"
        />
        <ul *ngIf="poblacions.length > 0 && isFocused" class="contextList">
          <li
            *ngFor="let poblacio of poblacions"
            (click)="setFormValue(poblacio.descripcio, poblacio.codiElement)"
          >
            {{ poblacio.descripcio }}
          </li>
        </ul>
      </div>
    </div>
  </div>

  <h5 class="title5">
    <span>{{ "REGISTRE.PROFESSIONAL_INFO_TITLE" | translate }}</span>
    @if(!isWeb){
      @if(seccions[2]) {
        <mat-icon (click)="setSeccio(2)">keyboard_arrow_up</mat-icon>
      }@else {
        <mat-icon (click)="setSeccio(2)">keyboard_arrow_down</mat-icon>
      }
    }
  </h5>
  @if(isWeb || seccions[2]) {
    <div class="row">
      <div class="col">
        <label for="dniUsuariRE"
          ><span class="red">*</span
          >{{ "REGISTRE.PROFESSIONAL_NIF_LABEL" | translate }}:</label
        >
        <input
          type="text"
          id="dniUsuariRE"
          formControlName="dniUsuari"
          placeholder="{{ 'REGISTRE.PROFESSIONAL_NIF_PLACEHOLDER' | translate }}"
          class="ctti-input-text"
          [disabled]="true"
        />
      </div>
      <div class="col">
        <label for="nomRE"
          ><span class="red">*</span
          >{{ "REGISTRE.PROFESSIONAL_NAME_LABEL" | translate }}:</label
        >
        <input
          type="text"
          id="nomRE"
          formControlName="nom"
          placeholder="{{ 'REGISTRE.PROFESSIONAL_NAME_PLACEHOLDER' | translate }}"
          class="ctti-input-text"
          [disabled]="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label for="adrecaElectronicaProfessionalRE">
          <span class="red">*</span>
          {{ "REGISTRE.PROFESSIONAL_EMAIL_LABEL" | translate }}:</label
        >
        <input
          matInput
          type="text"
          id="adrecaElectronicaProfessionalRE"
          formControlName="adrecaElectronicaProfessional"
          placeholder="{{ 'REGISTRE.PROFESSIONAL_EMAIL_PLACEHOLDER' | translate }}"
          class="ctti-input-text"
          required
        />
      </div>
      <div class="col">
        <label for="telefonProfessionalRE"
          >{{ "REGISTRE.PROFESSIONAL_PHONE_LABEL" | translate }}:</label
        >
        <input
          matInput
          type="text"
          id="telefonProfessionalRE"
          formControlName="telefonProfessional"
          placeholder="{{ 'REGISTRE.PROFESSIONAL_PHONE_PLACEHOLDER' | translate }}"
          class="ctti-input-text"
          maxlength="12"
        />
      </div>
    </div>
  }
  }
</app-sidepanel>
