import { Component } from '@angular/core';

@Component({
  selector: 'app-senseresultats',
  styleUrl: './sesnseresultats.component.scss',
  template: `
    <div class="no-resultats">
      <img src="../../../assets/images/noresult.png" alt="sense resultats">
      <p>{{ "CARREGADOR.NO_RESULT" | translate}}</p>
    </div>
    `,
})
export class SenseresultatsComponent {

}
