<app-toast></app-toast>
<main>
  <section id="benvinguda">
    <div>
      <h1 class="pageTitle">{{ "REGISTRE.WELCOME_MESSAGE" | translate }}</h1>
      <p class="noMobile">{{ "REGISTRE.WELCOME_DESCRIPTION" | translate }}</p>
      <p class="noMobile">{{ "REGISTRE.FIRST_ACCESS" | translate }}</p>
    </div>
    <img
      id="movil"
      src="../../../assets/images/movil.png"
      alt="{{ 'REGISTRE.WELCOME_IMAGE_ALT' | translate }}"
    />
  </section>
  <section id="formRegistre">
    <div class="text-centered">
      <h3 class="title3">{{ "REGISTRE.REGISTER_TITLE" | translate }}</h3>
      <p class="noMobile">{{ "REGISTRE.REGISTER_DESCRIPTION" | translate }}</p>
      <p>{{ "REGISTRE.REQUIRED_FIELDS" | translate }}
        <span class="red">*</span>
        {{ "REGISTRE.REQUIRED_FIELDS_2" | translate }}
        <!-- <span class="noMobile">{{ "REGISTRE.REQUIRED_FIELDS_2" | translate }}</span>
        <span class="mobile">{{ "MOBILE.REGISTRE" | translate }}</span> -->
      </p>
    </div>
    <form [formGroup]="formData">
      <h4 class="title4">
        <span>{{ "REGISTRE.COMPANY_INFO_TITLE" | translate }}</span>
        @if(!isWeb){ @if(seccions[0]) {
        <mat-icon (click)="setSeccio(0)">keyboard_arrow_up</mat-icon>
        }@else {
        <mat-icon (click)="setSeccio(0)">keyboard_arrow_down</mat-icon>
        } }
      </h4>
      @if(isWeb || seccions[0]) {
      <div class="col">
        <label for="nomEmpresa" class="mb-2"
          ><span class="red">*</span>
          {{ "REGISTRE.COMPANY_NAME_LABEL" | translate }}</label
        >
        <input
          type="text"
          id="nomEmpresa"
          formControlName="nomEmpresa"
          placeholder="{{ 'REGISTRE.COMPANY_NAME_PLACEHOLDER' | translate }}"
          class="ctti-input-text"
        />
      </div>
      <div class="row">
        <div class="col">
          <label for="nifEmpresa" class="mb-2"
            >{{ "REGISTRE.COMPANY_NIF_LABEL" | translate }}:</label
          >
          <input
            type="text"
            id="nifEmpresa"
            formControlName="nifEmpresa"
            placeholder="{{ 'REGISTRE.COMPANY_NIF_PLACEHOLDER' | translate }}"
            class="ctti-input-text"
            [disabled]="true"
          />
        </div>
        <div class="col">
          <label class="mb-2"
            ><span class="red">*</span>
            {{ "REGISTRE.ROLE_LABEL" | translate }}:</label
          >
          <div class="rols">
            <mat-checkbox
              id="carregador"
              [checked]="rol.value.includes('CARREGADOR')"
              (change)="onRoleChange('CARREGADOR', $event.checked)"
              ><span class="marge">{{
                "REGISTRE.ROLE_LOADER" | translate
              }}</span></mat-checkbox
            >
            <mat-checkbox
              id="transportista"
              [checked]="rol.value.includes('TRANSPORTISTA')"
              (change)="onRoleChange('TRANSPORTISTA', $event.checked)"
              ><span class="marge">{{
                "REGISTRE.ROLE_TRANSPORTER" | translate
              }}</span></mat-checkbox
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="emailEmpresa" class="mb-2"
            ><span class="red">*</span>
            {{ "REGISTRE.COMPANY_EMAIL_LABEL" | translate }}:</label
          >
          <input
            type="text"
            id="emailEmpresa"
            formControlName="emailEmpresa"
            placeholder="{{ 'REGISTRE.COMPANY_EMAIL_PLACEHOLDER' | translate }}"
            class="ctti-input-text"
          />
        </div>
        <div class="col">
          <label for="telefonEmpresa" class="mb-2"
            ><span class="red">*</span>
            {{ "REGISTRE.COMPANY_PHONE_LABEL" | translate }}:</label
          >
          <input
            type="tel"
            id="telefonEmpresa"
            formControlName="telefonEmpresa"
            placeholder="{{ 'REGISTRE.COMPANY_PHONE_PLACEHOLDER' | translate }}"
            class="ctti-input-text"
            maxlength="12"
          />
        </div>
      </div>
      }
      <br />
      <h4 class="title4">
        <span>{{ "REGISTRE.COMPANY_ADDRESS_TITLE" | translate }}</span>
        @if(!isWeb){ @if(seccions[1]) {
        <mat-icon (click)="setSeccio(1)">keyboard_arrow_up</mat-icon>
        }@else {
        <mat-icon (click)="setSeccio(1)">keyboard_arrow_down</mat-icon>
        } }
      </h4>
      @if(isWeb || seccions[1]) {
      <div class="col">
        <label for="adrecaEmpresa" class="mb-2"
          ><span class="red">*</span>
          {{ "REGISTRE.COMPANY_ADDRESS_LABEL" | translate }}</label
        >
        <input
          type="text"
          id="adrecaEmpresa"
          formControlName="adrecaEmpresa"
          placeholder="{{ 'REGISTRE.COMPANY_ADDRESS_PLACEHOLDER' | translate }}"
          class="ctti-input-text"
        />
      </div>
      <div class="row">
        <div class="col">
          <label for="codiPostalEmpresa" class="mb-2"
            ><span class="red">*</span>
            {{ "REGISTRE.COMPANY_ZIP_LABEL" | translate }}:</label
          >
          <input
            type="text"
            id="codiPostalEmpresa"
            formControlName="codiPostalEmpresa"
            placeholder="{{ 'REGISTRE.COMPANY_ZIP_PLACEHOLDER' | translate }}"
            class="ctti-input-text"
          />
        </div>
        <div class="col">
          <label class="mb-2"
            ><span class="red">*</span>
            {{ "REGISTRE.COMPANY_CITY_LABEL" | translate }}:</label
          >
          <div class="poblacio">
            <input
              type="text"
              id="poblacio"
              class="ctti-input-text"
              formControlName="poblacio"
              (focus)="onFocus()"
              (blur)="onBlur()"
              (input)="changeContextPoblacio($event)"
              placeholder="{{ 'STMC.CITY_PLACEHOLDER' | translate }}"
            />
            <ul *ngIf="poblacions.length > 0 && isFocused" class="contextList">
              <li
                *ngFor="let poblacio of poblacions"
                (click)="setFormValue(poblacio)"
              >
                {{ poblacio.descripcio }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      }
      <br />
      <h4 class="title4">
        <span>{{ "REGISTRE.PROFESSIONAL_INFO_TITLE" | translate }}</span>
        @if(!isWeb){ @if(seccions[2]) {
        <mat-icon (click)="setSeccio(2)">keyboard_arrow_up</mat-icon>
        }@else {
        <mat-icon (click)="setSeccio(2)">keyboard_arrow_down</mat-icon>
        } }
      </h4>
      @if(isWeb || seccions[2]) {
      <div class="row">
        <div class="col">
          <label for="nifProfessional" class="mb-2"
            >{{ "REGISTRE.PROFESSIONAL_NIF_LABEL" | translate }}:</label
          >
          <input
            type="text"
            id="nifProfessional"
            formControlName="nifProfessional"
            placeholder="{{
              'REGISTRE.PROFESSIONAL_NIF_PLACEHOLDER' | translate
            }}"
            class="ctti-input-text"
            [disabled]="true"
          />
        </div>
        <div class="col">
          <label for="nomProfessional" class="mb-2"
            ><span class="red">*</span>
            {{ "REGISTRE.PROFESSIONAL_NAME_LABEL" | translate }}:</label
          >
          <input
            type="text"
            id="nomProfessional"
            formControlName="nomProfessional"
            class="ctti-input-text"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="primerCognom" class="mb-2"
            ><span class="red">*</span>
            {{
              "REGISTRE.PROFESSIONAL_FIRST_SURNAME_LABEL" | translate
            }}:</label
          >
          <input
            type="text"
            id="primerCognom"
            formControlName="primerCognom"
            class="ctti-input-text"
          />
        </div>
        <div class="col">
          <label for="secCognom" class="mb-2"
            >{{
              "REGISTRE.PROFESSIONAL_SECOND_SURNAME_LABEL" | translate
            }}:</label
          >
          <input
            type="text"
            id="secCognom"
            formControlName="secCognom"
            class="ctti-input-text"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="emailProfessional" class="mb-2"
            ><span class="red">*</span>
            {{ "REGISTRE.PROFESSIONAL_EMAIL_LABEL" | translate }}:</label
          >
          <input
            type="text"
            id="emailProfessional"
            formControlName="emailProfessional"
            placeholder="{{
              'REGISTRE.PROFESSIONAL_EMAIL_PLACEHOLDER' | translate
            }}"
            class="ctti-input-text"
          />
        </div>
        <div class="col">
          <label for="telefonProfessional" class="mb-2">
            {{ "REGISTRE.PROFESSIONAL_PHONE_LABEL" | translate }}:</label
          >
          <input
            type="text"
            id="telefonProfessional"
            formControlName="telefonProfessional"
            placeholder="{{
              'REGISTRE.PROFESSIONAL_PHONE_PLACEHOLDER' | translate
            }}"
            class="ctti-input-text"
            maxlength="12"
          />
        </div>
      </div>
      }

      <div class="acceptarCondicions">
        <mat-checkbox
          id="acceptarCondicions"
          formControlName="acceptarCondicions"
        >
          <div class="lane">
            <span class="red">*</span>
            <p class="politiques">
              {{ "REGISTRE.INF_PROTECCCIO" | translate }}
              <span class="subrraya">{{
                "REGISTRE.INF_PROTECCCIO_DADES" | translate
              }}</span
              >:
            </p>
          </div>
        </mat-checkbox>
        @if(infoDesplegada) {
        <mat-icon class="icon" (click)="desplegarInfo()"
          >keyboard_arrow_up</mat-icon
        >
        } @else {
        <mat-icon class="icon" (click)="desplegarInfo()"
          >keyboard_arrow_down</mat-icon
        >
        }
      </div>
      @if(infoDesplegada) {
      <label class="info-title">
        {{ "REGISTRE.GDPR_TITLE" | translate }}:</label
      >
      <ul class="info-list">
        <li>
          <strong>{{ "REGISTRE.GDPR_TREATMENT_ID" | translate }}</strong>
          {{ "REGISTRE.GDPR_TREATMENT_DESC" | translate }}
        </li>
        <li>
          <strong>{{ "REGISTRE.GDPR_RESPONSIBLE" | translate }}</strong>
          {{ "REGISTRE.GDPR_RESPONSIBLE_DESC" | translate }}
        </li>
        <li>
          <strong>{{ "REGISTRE.GDPR_PURPOSE" | translate }}</strong>
          {{ "REGISTRE.GDPR_PURPOSE_DESC" | translate }}
        </li>
        <li>
          <strong>{{ "REGISTRE.GDPR_LEGAL_BASIS" | translate }}</strong>
          {{ "REGISTRE.GDPR_LEGAL_BASIS_DESC" | translate }}
        </li>
        <li>
          <strong>{{ "REGISTRE.GDPR_RECIPIENTS" | translate }}</strong>
          {{ "REGISTRE.GDPR_RECIPIENTS_DESC" | translate }}
        </li>
        <li>
          <strong>{{ "REGISTRE.GDPR_RIGHTS" | translate }}</strong>
          {{ "REGISTRE.GDPR_RIGHTS_DESC" | translate }}
        </li>
        <li>
          <strong>{{ "REGISTRE.GDPR_ADDITIONAL_INFO" | translate }}</strong>
          {{ "REGISTRE.GDPR_ADDITIONAL_INFO_DESC" | translate }}
        </li>
      </ul>
      }
      <div class="enviar">
        <button
          type="button"
          (click)="onSubmit()"
          [disabled]="isComplete()"
          [ngClass]="isComplete() ? 'disabled' : ''"
        >
          {{ "REGISTRE.SUBMIT_BUTTON" | translate }}
        </button>
      </div>
    </form>
  </section>
</main>
