<aside [ngClass]="show ? 'sidebarShow' : ''" aria-label="Menú lateral">
  <ul>
    <li [class.active]="isActive('/inici')" (click)="closeSidebar('/inici')">
      <a routerLink="/inici">
        <mat-icon size="24px" class="icon" alt="inici">home</mat-icon>
        {{ "MENU.INICI" | translate }}
      </a>
    </li>
    @if(carregador) {
    <li
      [class.active]="isActive('/carregador')"
      (click)="closeSidebar('/carregador')"
    >
      <a routerLink="/carregador">
        <img
          width="24"
          heigth="24"
          src="../../../assets/images/forklift_3.png"
          alt="carregador-enllaç"
          aria-hidden="true"
        />
        {{ "MENU.CARREGADOR" | translate }}
      </a>
    </li>
    } @if(transportista) {
    <li
      [class.active]="isActive('/transportista')"
      (click)="closeSidebar('/transportista')"
    >
      <a routerLink="/transportista">
        <mat-icon size="24px" class="icon" alt="transportista"
          >local_shipping</mat-icon
        >
        {{ "MENU.TRANSPORTISTA" | translate }}
      </a>
    </li>
    } @if(carrega) {
    <li
      class="carregam"
      [class.active]="isActive('/carrega-massiva')"
      (click)="closeSidebar('/carrega-massiva')"
    >
      <a routerLink="/carrega-massiva">
        <mat-icon size="24px" class="icon" alt="carrega-massiva"
          >note_add</mat-icon
        >
        {{ "MENU.CARREGA" | translate }}
      </a>
    </li>
    } @if(admin) {
    <li
      [class.active]="isActive('/administracio')"
      (click)="closeSidebar('/administracio')"
    >
      <a routerLink="/administracio">
        <mat-icon size="24px" class="icon" alt="administracio"
          >settings</mat-icon
        >
        {{ "MENU.ADMIN" | translate }}
      </a>
    </li>
    }
    <li
      class="perfil"
      [class.active]="isActive('/perfil')"
      (click)="closeSidebar('/perfil')"
    >
      <a routerLink="/">
        <mat-icon size="24px" class="icon" alt="account_circle"
          >account_circle</mat-icon
        >
        {{ "INICI.PERFIL" | translate }}
      </a>
    </li>
  </ul>
</aside>
